import * as React from "react"

const Footer = () => {
  return (
    <footer>
      © 2021 - <a href="https://nice-guy.jp">nice-guy.jp</a>
    </footer>
  )
}

export default Footer
