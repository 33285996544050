import * as React from "react"
import { Link } from "gatsby"
import Menu from "../components/menu";
import { StaticImage } from "gatsby-plugin-image"

const Header = ({title}) => {

  let header = (
    <h1 className="main-heading">
      <Link to="/">{title}</Link>
    </h1>
  )

  return (
    <header>
      <div
      style={{
        margin: `0 auto`,
        textAlign: `center`,
      }}
      >
      <Link to="/">
      <StaticImage
        src="../images/niceguy-logo.jpeg"
        alt="Profile picture"
      />
      </Link>
      </div>
    </header>
  )
}

export default Header
